import React from 'react';

interface TimelineItemProps {
    title: string;
    description: string;
    completed?: boolean;
    start?: boolean;
    end?: boolean;
    left?: boolean;
}

const TimelineItem = (props: TimelineItemProps) => {
    const { title, description, left, start, end, completed } = props;

    return (
        <li>
            {!start && <hr className={`${completed ? 'bg-primary' : ''}`} />}
            <div className={`${left ? 'timeline-start' : 'timeline-end'} timeline-box`}>
                <p className="font-semibold">{title}</p>
                <p className='text-sm'>{description}</p>
            </div>
            <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-5 h-5 ${completed ? 'text-primary' : 'text-clarity-green'}`}><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
            </div>
            {!end && <hr className={`${completed ? 'bg-primary' : ''}`} />}
        </li>
    );
};

export default TimelineItem;
