import React, { useEffect } from "react";
import Header from "../header/Header";
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import HomeHero from "./HomeHero";
import Footer from "../footer/Footer";
import { getUser } from "../../reducers/users";
import { useAppDispatch } from "../../reducers/store";

const HomeWrapper = () => {
    const navigate = useNavigate();
    const di = useAppDispatch();

    useEffect(() => {
        checkLoggedIn();
    }, []);

    const checkLoggedIn = async () => {
        try {
            const currentUser = await getCurrentUser();
            di(getUser(currentUser.userId))
        } catch (error) {
            navigate('/signin');
        }
    };
    return (
        <div className="w-screen">
            <Header />
            <HomeHero />
            <Footer />
        </div>
    );
};

export default HomeWrapper;