import React, { useEffect, useState, useRef } from "react";
import Header from "../header/Header";
import { getCurrentUser, updatePassword } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import { fetchAsset, getPreSignedURL, getUser, updateUser, uploadFile } from "../../reducers/users";
import { useAppDispatch } from "../../reducers/store";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ProfileSchema from "../../validation/ProfileValidation";
import { FetchAssetData, PasswordFormData, PreSignedData, ProfileFormData } from "../../types/UserTypes";
import { useSelector } from 'react-redux';
import { selectUser } from "../../reducers/users";
import ConfirmationAlert from "../alerts/ConfirmationAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import ResetPasswordSchema from "../../validation/ResetPasswordValidation";
import { PROFILE_PICTURE } from "../../strings/api-consts";

const Profile = () => {
    const navigate = useNavigate();
    const di = useAppDispatch();
    const fileInput = useRef<HTMLInputElement>(null);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(ProfileSchema),
    });
    const { register: resetRegister, handleSubmit: resetHandleSubmit, setValue: resetSetValue, formState: { errors: resetErrors } } = useForm({
        resolver: yupResolver(ResetPasswordSchema),
    });

    const { user } = useSelector(selectUser);
    const [userUpdated, setUserUpdated] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<string>("");
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [profilePicture, setProfilePicture] = useState<string>("");

    useEffect(() => {
        checkLoggedIn();
    }, []);

    const checkLoggedIn = async () => {
        try {
            const currentUser = await getCurrentUser();
            di(getUser(currentUser.userId))
        } catch (error) {
            navigate('/signin');
        }
    };

    const handleProfileUpdate = async (formData: ProfileFormData) => {
        setUpdateLoading(true);
        try {
            await di(updateUser({
                id: user.id,
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.username,
                phone: formData.phone,
                street_address: formData.street_address,
                town: formData.town,
                post_code: formData.post_code
            }));
            setUpdateLoading(false);
            setUserUpdated(true);
        } catch (error: unknown) {
            setUpdateLoading(false);
            setUserUpdated(false);
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    };

    const handlePasswordUpdate = async (formData: PasswordFormData) => {
        setPasswordLoading(true);
        try {
            await updatePassword({ oldPassword: formData.old_password, newPassword: formData.new_password });
            resetSetValue('old_password', '');
            resetSetValue('new_password', '');
            setPasswordLoading(false);
            setPasswordUpdated(true);
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setPasswordError(message);
            setPasswordLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            setValue('first_name', user.first_name);
            setValue('last_name', user.last_name);
            setValue('username', user.email);
            setValue('phone', user.phone);
            setValue('street_address', user.street_address);
            setValue('town', user.town);
            setValue('post_code', user.post_code);

            setProfilePicture('');
            fetchProfilePicture();
        }
    }, [user]);

    const handleDivClick = () => {
        fileInput.current?.click();
    };

    const fetchProfilePicture = async () => {
        const assetParams: FetchAssetData = {
            user_id: user.id,
            key: PROFILE_PICTURE
        }
        try {
            const imageURL = await di(fetchAsset(assetParams));
            setProfilePicture(imageURL.url);
        } catch (error) {
            setProfilePicture('');
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            try {
                setUploadLoading(true);
                const file = event.target.files[0];
                const fileParams: PreSignedData = {
                    user_id: user.id,
                    content_type: file.type
                }
                const presignedUrl = await di(getPreSignedURL(fileParams));
                await di(uploadFile(presignedUrl.url, fileParams, file));
                fetchProfilePicture();
                setUploadLoading(false);
            } catch (error) {
                setUploadLoading(false);
            }
        }
    };

    const showLoadingSpinner = () => {
        return <div className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-white rounded-full dark:text-clarity-green" role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
        </div>
    };

    return (
        <div className="w-screen">
            <Header />
            <div className="block max-w-[960px] mx-auto mt-10 ">
                <p className="logo !text-4xl">Your Profile</p>
                <p>Here you can update all your details that relate to your mortgage journey.</p>
                <div className="flex mt-10 font-circular w-full">
                    <input type="file" ref={fileInput} onChange={handleFileChange} style={{ display: 'none' }} />
                    <div className="!block">
                        <div className="mt-4 mr-4">
                            <div className="avatar placeholder absolute">
                                <div className="w-48 h-48 bg-neutral text-neutral-content rounded-full">
                                    <span className="text-3xl">+</span>
                                </div>
                            </div>
                            <div className="avatar absolute">
                                <div className="w-48 h-48 rounded-full">
                                    <img src={profilePicture} />
                                </div>
                            </div>
                        </div>

                        <p className="relative top-[210px] mt-2 text-sm font-circular w-48 text-center">click here to upload a new profile picture</p>
                        <button
                            onClick={handleDivClick}
                            className="btn relative top-[200px] w-48 p-2 bg-clarity-green text-[#fff] rounded mt-4">
                            {!uploadLoading ? 'upload image' : showLoadingSpinner()}
                        </button>
                    </div>

                    <div className="ml-4">
                        <form onSubmit={handleSubmit(handleProfileUpdate)}>
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">First name</span>
                                </div>
                                <input {...register('first_name')} type="text" placeholder="First name" className="input input-bordered" />
                            </label>
                            {errors.first_name && <p className="block text-error text-xs">{errors.first_name.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Last name</span>
                                </div>
                                <input {...register('last_name')} type="text" placeholder="Last name" className="input input-bordered" />
                            </label>
                            {errors.last_name && <p className="block text-error text-xs">{errors.last_name.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Email</span>
                                </div>
                                <input {...register('username')} type="text" placeholder="Email" className="input input-bordered" />
                            </label>
                            {errors.username && <p className="block text-error text-xs">{errors.username.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Phone</span>
                                </div>
                                <input {...register('phone')} type="text" placeholder="Phone" className="input input-bordered" />
                            </label>
                            {errors.phone && <p className="block text-error text-xs">{errors.phone.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Street address</span>
                                </div>
                                <input {...register('street_address')} type="text" placeholder="Street address" className="input input-bordered" />
                            </label>
                            {errors.street_address && <p className="block text-error text-xs">{errors.street_address.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Town</span>
                                </div>
                                <input {...register('town')} type="text" placeholder="Town" className="input input-bordered" />
                            </label>
                            {errors.town && <p className="block text-error text-xs">{errors.town.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Postcode</span>
                                </div>
                                <input {...register('post_code')} type="text" placeholder="Postcode" className="input input-bordered" />
                            </label>
                            {errors.post_code && <p className="block text-error text-xs">{errors.post_code.message}</p>}
                            <button
                                type="submit"
                                className="btn btn-square block w-full p-2 bg-clarity-green text-[#fff] rounded mt-6">
                                {!updateLoading ? 'save changes' : showLoadingSpinner()}
                            </button>
                        </form>
                        {
                            error && <ErrorAlert message={error} />
                        }
                        {userUpdated && <ConfirmationAlert message={'Your profile has been updated'} />}

                        {/* Reset Password Form */}
                        <p className="logo !text-2xl mt-20">Reset password</p>
                        <p>Please enter a new password below</p>
                        <form onSubmit={resetHandleSubmit(handlePasswordUpdate)}>
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">Old password</span>
                                </div>
                                <input {...resetRegister('old_password')} type="password" placeholder="Confirm password" className="input input-bordered" />
                            </label>
                            {resetErrors.old_password && <p className="block text-error text-xs">{resetErrors.old_password.message}</p>}
                            <label className="form-control w-96">
                                <div className="label">
                                    <span className="label-text">New password</span>
                                </div>
                                <input {...resetRegister('new_password')} type="password" placeholder="New password" className="input input-bordered" />
                            </label>
                            {resetErrors.new_password && <p className="block text-error text-xs">{resetErrors.new_password.message}</p>}
                            <button
                                type="submit"
                                className="block w-full p-2 bg-clarity-green text-[#fff] rounded mt-6">
                                {!passwordLoading ? 'update password' : showLoadingSpinner()}
                            </button>
                        </form>
                        {
                            passwordError && <ErrorAlert message={error} />
                        }
                        {passwordUpdated && <ConfirmationAlert message={'Your password has been updated'} />}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Profile;