import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import { useSelector } from 'react-redux';
import { fetchAsset, selectUser } from "../../reducers/users";
import { FetchAssetData } from "../../types/UserTypes";
import { PROFILE_PICTURE } from "../../strings/api-consts";
import { useAppDispatch } from "../../reducers/store";

const Header = () => {
    const navigate = useNavigate();
    const di = useAppDispatch();
    const { user } = useSelector(selectUser);
    const [error, setError] = useState<string>("");
    const [userInitials, setUserInitials] = useState<string>("");
    const [profilePicture, setProfilePicture] = useState<string>("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/signin');
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    }

    useEffect(() => {
        if (error) {
            setTimeout(function () {
                setError('');
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        if (user) {
            fetchProfilePicture();
        }
    }, [user]);

    const fetchProfilePicture = async () => {
        const assetParams: FetchAssetData = {
            user_id: user.id,
            key: PROFILE_PICTURE
        }
        try {
            const imageURL = await di(fetchAsset(assetParams));
            setProfilePicture(imageURL.url);
        } catch (error) {
            setUserInitials(`${user.first_name[0]}${user.last_name[0]}`);
            setProfilePicture("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
        }
    };

    return (
        <>
            <div className="navbar bg-base-100 max-w-[1640px] mx-auto">
                <div className="flex-1">
                    <a className="logo btn btn-ghost !text-3xl" onClick={() => { navigate('/home') }}>clarity</a>
                </div>
                <div className="flex-none mr-10">
                    <ul className="menu menu-horizontal px-1 font-circular text-clarity-green text-base">
                        <li><a onClick={() => { navigate('/home') }}>home</a></li>
                        <li><a onClick={() => { navigate('/home') }}>timeline</a></li>
                        <li><a onClick={() => { navigate('/home') }}>contact</a></li>
                    </ul>
                </div>
                <div className="flex-none">
                    <div className="dropdown dropdown-end">
                        <button tabIndex={0} className="btn btn-ghost btn-circle">
                            <div className="indicator">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 stroke-clarity-green" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
                                <span className="badge badge-xs badge-primary indicator-item"></span>
                            </div>
                        </button>
                        <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-80 bg-base-100 shadow">
                            <div role="alert" className="alert shadow-lg">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <div>
                                    <h3 className="font-bold">welcome to clarity!</h3>
                                    <div className="text-xs">You have 1 unread message</div>
                                </div>
                                <button className="btn btn-sm">See</button>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar placeholder">
                            <div className="bg-neutral text-neutral-content rounded-full w-10 absolute">
                                <span>{userInitials}</span>
                            </div>
                            <div className="w-10 rounded-full absolute">
                                <img src={profilePicture} onError={(e) => { e.currentTarget.style.display = 'none'; }} />
                            </div>
                        </div>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            <li>
                                <a className="justify-between" onClick={() => { navigate('/profile') }}>
                                    profile
                                    <span className="badge">new</span>
                                </a>
                            </li>
                            <li><a onClick={() => { navigate('/settings') }}>settings</a></li>
                            <li><a onClick={handleSignOut}>logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                error && (
                    <div className="toast toast-end" >
                        <div className="alert alert-error">
                            <span>{error}</span>
                        </div>
                    </div >
                )
            }
        </>
    );
};

export default Header;