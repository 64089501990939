import React, { useState } from "react";
import ErrorAlert from "../alerts/ErrorAlert";
import { confirmSignUp, autoSignIn, confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { ScreenType } from "../../types/LoginTypes";

interface CodeInputProps {
    username: string;
    codeScreen: string;
    setScreen: React.Dispatch<React.SetStateAction<ScreenType>>;
    setPasswordReset: React.Dispatch<React.SetStateAction<boolean>>;
}

const CodeInput = (props: CodeInputProps) => {
    const { username, codeScreen, setScreen, setPasswordReset } = props;
    const navigate = useNavigate();
    const [inputtedCode, setInputtedCode] = useState("");
    const [error, setError] = useState<string>("");
    const [newPassword, setNewPassword] = useState("");

    const handleSubmitClick = () => {
        if (codeScreen === "SignUp") {
            handleSignUpConfirmation();
        } else {
            handleConfirmResetPassword();
        }
    };

    const handleSignUpConfirmation = async () => {
        try {
            const { isSignUpComplete } = await confirmSignUp({
                username,
                confirmationCode: inputtedCode
            });

            if (isSignUpComplete) {
                handleAutoSignIn();
            }
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    };

    const handleConfirmResetPassword = async () => {
        try {
            await confirmResetPassword({ username, confirmationCode: inputtedCode, newPassword });
            setPasswordReset(true);
            setScreen('SignIn');
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    }

    const handleAutoSignIn = async () => {
        try {
            const signInOutput = await autoSignIn();
            if (signInOutput) {
                return navigate('/home');
            }
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    };

    return (
        <div className="block w-80">
            <p className="font-circular text-clarity-green mb-2 text-3xl">enter code</p>
            <p className="text-sm mb-8 text-clarity-green">A code was sent to {username}. Please enter it below.</p>
            {
                codeScreen === "CodeVerification" &&
                <input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New password"
                    type="password"
                    className="block w-full p-2 border border-clarity-green rounded mt-2"
                />
            }
            <input
                value={inputtedCode}
                onChange={(e) => setInputtedCode(e.target.value)}
                placeholder="Code"
                className="block w-full p-2 border border-clarity-green rounded mt-2"
            />

            <button onClick={handleSubmitClick} className="block w-full p-2 bg-clarity-green text-[#fff] rounded mt-2">submit</button>
            {
                error && <ErrorAlert message={error} />
            }
        </div>
    );
};

export default CodeInput;