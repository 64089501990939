import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import { CodeScreenType, ScreenType } from "../../types/LoginTypes";
import CodeInput from "./CodeInput";

const LoginWrapper = () => {
    const [screen, setScreen] = useState<ScreenType>("SignIn");
    const [codeScreen, setCodeScreen] = useState<CodeScreenType>("SignUp");
    const [usernameForCode, setUsernameForCode] = useState<string>("");
    const [passwordReset, setPasswordReset] = useState<boolean>(false);

    const showLoginScreens = () => {
        switch (screen) {
            case 'SignIn':
                return <SignIn setScreen={setScreen} passwordReset={passwordReset}/>;
            case 'SignUp':
                return <SignUp setScreen={setScreen} setUsernameForCode={setUsernameForCode} setCodeScreen={setCodeScreen}/>;
            case 'ForgotPassword':
                return <ForgotPassword setScreen={setScreen} setUsernameForCode={setUsernameForCode} setCodeScreen={setCodeScreen}/>;
            case 'CodeVerification':
                return <CodeInput username={usernameForCode} codeScreen={codeScreen} setScreen={setScreen} setPasswordReset={setPasswordReset}/>;
            default:
                return <SignIn setScreen={setScreen}/>;
        }
    };

    return (
        <div className="flex h-screen w-screen">
            <p className="logo absolute top-2 left-8">clarity</p>
            <div className="w-1/2 flex justify-center items-center bg-gray-100">
                {
                    showLoginScreens()
                }
            </div>
            <div className="w-1/2">
                <div className="h-full w-full object-cover bg-clarity-green" ></div>
                {/* <img src="https://placehold.co/1200x800.png" alt="Descriptive alt text" className="h-full w-full object-cover" /> */}
            </div>
        </div>
    );
};

export default LoginWrapper;