import React from "react";

interface DocIconProps {
    height?: string;
    width?: string;
    fill?: string;
}

const DocIcon = (props: DocIconProps) => {
    const { height, width, fill } = props;

    return (
        <svg width={width ? width : '30'} height={height ? height : '32px'} viewBox="0 0 24 25" fill={fill ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_131)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.875 14H3.375C2.96079 14 2.625 14.3358 2.625 14.75V20C2.625 20.4142 2.96079 20.75 3.375 20.75H4.875C6.73896 20.75 8.25 19.239 8.25 17.375C8.25 15.511 6.73896 14 4.875 14ZM4.875 19.25H4.125V15.5H4.875C5.91053 15.5 6.75 16.3395 6.75 17.375C6.75 18.4105 5.91053 19.25 4.875 19.25ZM20.7684 18.7897C21.0677 19.076 21.0782 19.5507 20.7919 19.85C20.2641 20.4182 19.5255 20.7437 18.75 20.75C17.0953 20.75 15.75 19.2359 15.75 17.375C15.75 15.5141 17.0953 14 18.75 14C19.5255 14.0063 20.2641 14.3318 20.7919 14.9C21.0718 15.2002 21.0583 15.6696 20.7618 15.9534C20.4652 16.2371 19.9957 16.2297 19.7081 15.9369C19.4621 15.6661 19.1157 15.5082 18.75 15.5C17.9231 15.5 17.25 16.3438 17.25 17.375C17.25 18.4062 17.9231 19.25 18.75 19.25C19.1157 19.2418 19.4621 19.0839 19.7081 18.8131C19.9945 18.5139 20.4692 18.5034 20.7684 18.7897ZM12 14C10.3453 14 9 15.5141 9 17.375C9 19.2359 10.3453 20.75 12 20.75C13.6547 20.75 15 19.2359 15 17.375C15 15.5141 13.6547 14 12 14ZM12 19.25C11.1731 19.25 10.5 18.4062 10.5 17.375C10.5 16.3438 11.1731 15.5 12 15.5C12.8269 15.5 13.5 16.3438 13.5 17.375C13.5 18.4062 12.8269 19.25 12 19.25ZM4.5 11.75C4.91421 11.75 5.25 11.4142 5.25 11V4.25H13.5V8.75C13.5 9.16421 13.8358 9.5 14.25 9.5H18.75V11C18.75 11.4142 19.0858 11.75 19.5 11.75C19.9142 11.75 20.25 11.4142 20.25 11V8.75C20.2502 8.55103 20.1712 8.36015 20.0306 8.21938L14.7806 2.96937C14.6399 2.82876 14.449 2.74984 14.25 2.75H5.25C4.42157 2.75 3.75 3.42157 3.75 4.25V11C3.75 11.4142 4.08579 11.75 4.5 11.75ZM15 5.31031L17.6897 8H15V5.31031Z" fill="#141414" />
            </g>
            <defs>
                <clipPath id="clip0_2_131">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DocIcon;
