import React, { useState } from "react";
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth';
import { CodeScreenType, ScreenType } from "../../types/LoginTypes";
import ErrorAlert from "../alerts/ErrorAlert";
import { ForgotPasswordFormData } from "../../types/UserTypes";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ForgotPasswordSchema from "../../validation/ForgotPasswordValidation";

interface ForgotPasswordProps {
    setScreen: React.Dispatch<React.SetStateAction<ScreenType>>;
    setUsernameForCode: React.Dispatch<React.SetStateAction<string>>;
    setCodeScreen: React.Dispatch<React.SetStateAction<CodeScreenType>>;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const { setScreen, setUsernameForCode, setCodeScreen } = props;
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(ForgotPasswordSchema),
    });
    const [error, setError] = useState<string>("");

    const handleResetPassword = async (formData: ForgotPasswordFormData) => {
        try {
            const output = await resetPassword({ username: formData.username });
            handleResetPasswordNextSteps(output);
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
        }
    }

    const handleResetPasswordNextSteps = (output: ResetPasswordOutput) => {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE': {
                setCodeScreen('CodeVerification');
                setScreen('CodeVerification');
                break;
            }
            case 'DONE':
                console.log('Successfully reset password.');
                break;
        }
    }

    return (
        <div className="block w-80">
            <form onSubmit={handleSubmit(handleResetPassword)}>
                <p className="font-circular text-clarity-green mb-8 text-3xl">forgot password</p>
                <input {...register('username')} onChange={(e) => {
                    setUsernameForCode(e.target.value);
                }} placeholder="Email" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.username && <p className="block text-error text-xs">{errors.username.message}</p>}
                <button type="submit" className="block w-full p-2 bg-clarity-green text-[#fff] rounded mt-2">reset password</button>
                <div className="flex justify-between mt-4">
                    <p className="cursor-pointer text-sm" onClick={() => setScreen('SignIn')}>already registered? sign in</p>
                </div>
                {
                    error && <ErrorAlert message={error} />
                }
            </form>
        </div>
    );
};

export default ForgotPassword;