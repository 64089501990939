import React, { useEffect } from "react";
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import UploadCard from "../upload-card/UploadCard";
import TimelineItem from "../timeline/TimelineItem";

const HomeHero = () => {
    const navigate = useNavigate();

    useEffect(() => {
        checkLoggedIn();
    }, []);

    const checkLoggedIn = async () => {
        try {
            await getCurrentUser();
        } catch (error) {
            navigate('/signin');
        }
    };
    return (
        <div className="block max-w-[960px] mx-auto mt-10">
            <p className="logo !text-4xl">Jordan & Anna&#39;s Mortgage Journey</p>
            <p>Welcome to your personalized mortgage journey. Here, you can track your progress, understand your next steps, and find
                resources to help you along the way.</p>
            <ul className="timeline timeline-vertical mt-10">
                <TimelineItem title="Plan out Costs" description="Before you start house hunting, get an idea of how much your legal, lender, removals and broker fees could be." left start />
                <TimelineItem title="Decision in Principle" description="Get a decision in principle (DIP) from a lender. This will give you a rough idea of how much you could borrow." />
                <TimelineItem title="Find your home" description="Now you know how much you might be able to borrow it's time to find your dream home!" left />
                <TimelineItem title="Put in an Offer" description="When making an offer the listing agent will ask for your decision in principle so make sure to have this handy." />
                <TimelineItem title="Sale Agreed" description="Once all parties are happy you will be asked for a holding deposit and given a time frame to get a mortgage." left />
                <TimelineItem title="Mortgage Application" description="Next you'll complete the mortgage application. This can be done through a broker or directly with a lender." />
                <TimelineItem title="House Valuation" description="Your provider will get a valuation on the property to prove the value is worth the amount they're lending." left />
                <TimelineItem title="Mortgage Approval" description="Congratulations, your mortgage has been approved and will be sent to your solicitor. They will start the next steps." />
                <TimelineItem title="Sort Insurances" description="We recommend you to invest in good life and buildings & content insurance to help protect you and your new home." left />
                <TimelineItem title="Closing" description="Sign the contracts and agree on a moving in date. On the day the money will be sent and you will get the keys." end />
            </ul>
            <div>
                <p className="logo !text-2xl mt-5">What Do I Have To Do?</p>
                <p>To keep the process moving could you please upload the below documents so your mortgage advisor can have them ready when the bank requests them.</p>
                <UploadCard uploadText='Payslip dated within the last 3 months' />
                <UploadCard uploadText='Bank statement dated within the last 3 months' />
                <UploadCard uploadText='Utility bill dated within the last 3 months' />
            </div>
            <div>
                <p className="logo !text-2xl mt-10">What happens now?</p>
                <p>Welcome to your personalized mortgage journey. Here, you can track your progress, understand your next steps, and find
                    resources to help you along the way.</p>
                <button className="btn btn-primary mt-4">Find Out More</button>
            </div>
        </div>
    );
};

export default HomeHero;