import React, { useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import './style.css'
import config from './amplifyconfiguration.json';
Amplify.configure(config);

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      await getCurrentUser();
      navigate('/home');
    } catch (error) {
      navigateToSignin();
    }
  };

  const navigateToSignin = () => {
    return navigate('/signin');
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin inline-block w-12 h-12 border-[3px] border-current border-t-transparent text-clarity-green rounded-full dark:text-white" role="status" aria-label="loading">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default App;
