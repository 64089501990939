import * as yup from 'yup';

const SignUpSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    username: yup.string().email('Must be a valid email').required('Email is required'),
    password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    phone: yup.string().matches(/^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/, 'Must be a valid UK phone number').required('Phone number is required'),
    post_code: yup.string().matches(/^(BT[0-9]{1,2}\s?[0-9][A-Z]{2})$/i, 'Must be a valid NI postcode').required('Postcode is required')
});

export default SignUpSchema;