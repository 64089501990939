import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './users';

const createStore = (initialState?: any) => {
    return configureStore({
        reducer: {
            user: userReducer,
        },
        middleware: [thunk],
        preloadedState: initialState
    });
}

export default createStore;