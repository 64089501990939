import React, { useState } from "react";
import { signUp } from 'aws-amplify/auth';
import { CodeScreenType, ScreenType } from "../../types/LoginTypes";
import ErrorAlert from "../alerts/ErrorAlert";
import { useAppDispatch } from "../../reducers/store";
import { createUser } from "../../reducers/users";
import SignUpSchema from "../../validation/SignUpValidation";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignUpFormData } from "../../types/UserTypes";

interface SignUpProps {
    setScreen: React.Dispatch<React.SetStateAction<ScreenType>>;
    setUsernameForCode: React.Dispatch<React.SetStateAction<string>>;
    setCodeScreen: React.Dispatch<React.SetStateAction<CodeScreenType>>;
}

const SignUp = (props: SignUpProps) => {
    const { setScreen, setUsernameForCode, setCodeScreen } = props;
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(SignUpSchema),
    });
    const di = useAppDispatch();
    const [error, setError] = useState<string>("");

    const handleSignUp = async (formData: SignUpFormData) => {
        try {
            const res = await signUp({
                username: formData.username,
                password: formData.password,
                options: {
                    userAttributes: {
                        email: formData.username
                    },
                    autoSignIn: true
                }
            });
            await di(createUser({
                id: res.userId,
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.username,
                phone: formData.phone,
                post_code: formData.post_code
            }));
            setCodeScreen('SignUp');
            setScreen('CodeVerification');
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
            console.log('error signing up:', error);
        }
    };

    return (
        <div className="block w-80">
            <form onSubmit={handleSubmit(handleSignUp)}>
                <p className="font-circular text-clarity-green mb-8 text-3xl">sign up</p>
                <div className="flex w-full mt-2">
                    <input {...register('first_name')} placeholder="First name" className="block w-1/2 p-2 border border-clarity-green rounded mr-2" />
                    <input {...register('last_name')} placeholder="Last name" className="block w-1/2 p-2 border border-clarity-green rounded" />
                </div>
                <div className="flex w-full">
                    <div className="block w-1/2">
                        {errors.first_name && <p className="text-error text-xs">{errors.first_name.message}</p>}
                    </div>
                    <div className="block w-1/2">
                        {errors.last_name && <p className="text-error text-xs ml-1">{errors.last_name.message}</p>}
                    </div>
                </div>
                <input {...register('username')} placeholder="Email" className="block w-full p-2 border border-clarity-green rounded mt-2" onChange={(e) => setUsernameForCode(e.target.value)} />
                {errors.username && <p className="block text-error text-xs">{errors.username.message}</p>}
                <input {...register('password')} placeholder="Password" type="password" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.password && <p className="block text-error text-xs">{errors.password.message}</p>}
                <input {...register('phone')} placeholder="Phone" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.phone && <p className="block text-error text-xs">{errors.phone.message}</p>}
                <input {...register('post_code')} placeholder="Postcode" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.post_code && <p className="block text-error text-xs">{errors.post_code.message}</p>}
                <button
                    type="submit"
                    className="block w-full p-2 bg-clarity-green text-[#fff] rounded mt-2">
                    sign up
                </button>
                <div className="flex justify-between mt-4">
                    <p className="cursor-pointer text-sm" onClick={() => setScreen('SignIn')}>already registered? sign in</p>
                    <p className="cursor-pointer text-sm" onClick={() => setScreen('ForgotPassword')}>forgot password</p>
                </div>
                {
                    error && <ErrorAlert message={error} />
                }
            </form>
        </div>
    );
};

export default SignUp;