import React from "react";
import DocIcon from "../icons/DocIcon";

interface UploadCardProps {
  uploadText: string;
}

const UploadCard = (props: UploadCardProps) => {
  const { uploadText } = props;
  return (
    <>
      <div className="w-full bg-[#F5F5F5] rounded flex justify-between items-center p-4 mt-4">
        <div className="flex items-center">
          <div className="p-4 bg-[#E8E8E8] rounded">
            <DocIcon />
          </div>
          <p className="ml-4">{uploadText}</p>
        </div>
        <input type="file" className="file-input file-input-bordered file-input-secondary max-w-xs" />
      </div>
    </>
  );
};

export default UploadCard;