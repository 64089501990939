import { API_URL, FETCH_ASSET_ROUTE, GET_PRESIGNED_ROUTE, UPDATE_USER_ROUTE, USER_ROUTE } from "../../strings/api-consts";
import { FetchAssetData, FileUploadResponse, PreSignedData, User } from "../../types/UserTypes";
import { setUser } from "./users.slice";

export const getUser = (id: string) => async (dispatch: any) => {
    await fetch(`${API_URL + USER_ROUTE + "?id=" + id}`)
        .then((res) => res.json())
        .then((res) => {
            dispatch(setUser(res));
        }).catch((err) => { console.log(err) });
};

export const createUser = (user: User) => async (dispatch: any) => {
    await fetch(`${API_URL + USER_ROUTE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    })
        .then((res) => res.json())
        .then((res) => {
            dispatch(setUser(res));
        });
};

export const updateUser = (user: User) => async (dispatch: any) => {
    await fetch(`${API_URL + UPDATE_USER_ROUTE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    })
        .then((res) => res.json())
        .then((res) => {
            dispatch(setUser(res));
        }).catch((err) => { console.log(err) });
};

export const getPreSignedURL = (presignedData: PreSignedData) => async (): Promise<FileUploadResponse> => {
    return await fetch(`${API_URL + GET_PRESIGNED_ROUTE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(presignedData),
    })
        .then((res) => res.json())
        .then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
};

export const uploadFile = (url: string, presignedData: PreSignedData, file: File) => async () => {
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': presignedData.content_type,
        },
        body: file,
    })
        .then((res) => res.json())
        .then((res) => {            
            return res;
        }).catch((err) => { console.log(err) });
}

export const fetchAsset = (fetchData: FetchAssetData) => async (): Promise<FileUploadResponse> => {
    return await fetch(`${API_URL + FETCH_ASSET_ROUTE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fetchData),
    })
        .then((res) => res.json())
        .then((res) => {
            return res;
        }).catch((err) => { console.log(err); });
}