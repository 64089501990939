import React, { useState } from "react";
import { signIn } from 'aws-amplify/auth';
import { ScreenType } from "../../types/LoginTypes";
import ErrorAlert from "../alerts/ErrorAlert";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInFormData } from "../../types/UserTypes";
import SignInSchema from "../../validation/SignInValidation";
import ConfirmationAlert from "../alerts/ConfirmationAlert";


interface SignInProps {
    setScreen: React.Dispatch<React.SetStateAction<ScreenType>>;
    passwordReset?: boolean;
}

const SignIn = (props: SignInProps) => {
    const { setScreen, passwordReset } = props;
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(SignInSchema),
    });
    const navigate = useNavigate();

    const [error, setError] = useState<string>("");

    const handleSignIn = async (formData: SignInFormData) => {
        setError("");
        try {
            const { isSignedIn } = await signIn({
                username: formData.username,
                password: formData.password
            });
            if (isSignedIn) {
                return navigate('/home');
            }
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error."
            setError(message);
            console.log('error signing up:', error);
        }
    };

    return (
        <div className="block w-80">
            <form onSubmit={handleSubmit(handleSignIn)}>
                <p className="font-circular text-clarity-green mb-8 text-3xl">sign in</p>
                <input {...register('username')} placeholder="Email" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.username && <p className="block text-error text-xs">{errors.username.message}</p>}
                <input {...register('password')} placeholder="Password" type="password" className="block w-full p-2 border border-clarity-green rounded mt-2" />
                {errors.password && <p className="block text-error text-xs">{errors.password.message}</p>}
                <button type="submit" className="block w-full p-2 bg-clarity-green text-[#fff] rounded mt-2">sign in</button>
                <div className="flex justify-between mt-4">
                    <p className="cursor-pointer text-sm" onClick={() => setScreen('SignUp')}>new here? register</p>
                    <p className="cursor-pointer text-sm" onClick={() => setScreen('ForgotPassword')}>forgot password</p>
                </div>
                {
                    error && <ErrorAlert message={error} />
                }
                {passwordReset && <ConfirmationAlert message={'Your password has been reset'} />}
            </form>
        </div>
    );
};

export default SignIn;