import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserState {
    user: any | null;
}

const initialState: UserState = {
    user: undefined,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload;
        }
    },
});
export const selectUser = (state: RootState) => state.user;

export const { setUser } = userSlice.actions;
export default userSlice.reducer;